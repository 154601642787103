.link {
  position: fixed;
  right: 32px;
  bottom: 65px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: linear-gradient(180deg, #86F07C 0%, #5BC042 100%);
  box-shadow: 0 4px 32px 0 #65CA4F52;
  border-radius: 100%;
}
