$gray0: #fff;
$gray50: #fafafa;
$gray100: #eee;
$gray200: #e2e2e2;
$gray300: #cbcbcb;
$gray400: #afafaf;
$gray500: #757575;
$gray600: #545454;
$gray700: #333;
$gray800: #1f1f1f;
$gray900: #171717;
$gray1000: #000;
$indigo50: #ebebff;
$indigo100: #e0e0ff;
$indigo200: #c0bfff;
$indigo300: #8b8ae5;
$indigo400: #6f6edb;
$indigo500: #5856d6;
$indigo600: #4948b2;
$indigo700: #3f3d99;
$indigo800: #343480;
$blue50: #ebf1ff;
$blue100: #e0eaff;
$blue200: #bfd3ff;
$blue300: #8eaced;
$blue400: #3c68c7;
$blue500: #003cbe;
$blue600: #003099;
$blue700: #002880;
$blue800: #002066;
$green50: #e5f9ea;
$green100: #d7f5df;
$green200: #aee9bd;
$green300: #85dd9b;
$green400: #5dd27a;
$green500: #34c759;
$green600: #2a9f47;
$green700: #1f7735;
$green800: #1f7735;
$yellow50: #fffce6;
$yellow100: #fffacd;
$yellow200: #fff59a;
$yellow300: #fff066;
$yellow400: #ffeb33;
$yellow500: #ffe603;
$yellow600: #e5d32e;
$yellow700: #998a02;
$yellow800: #665c01;
$orange50: #fff4e5;
$orange100: #ffeacc;
$orange200: #ffd599;
$orange300: #ffbf66;
$orange400: #fa3;
$orange500: #ff9500;
$orange600: #c70;
$orange700: #995900;
$orange800: #663c00;
$red50: #ffebea;
$red100: #ffd8d6;
$red200: #ffb1ac;
$red300: #ff8983;
$red400: #ff6259;
$red500: #ff3b30;
$red600: #cc2f26;
$red700: #99231d;
$red800: #661813;
$brown50: #f5ede9;
$brown100: #ebe0db;
$brown200: #d2bab0;
$brown300: #b18976;
$brown400: #99644c;
$brown500: #744c39;
$brown600: #5b3c2d;
$brown700: #4d3226;
$brown800: #332119;
$stickerBoxPrimary: #181a1d;
$stickerBoxSecondary: #26282d;
$stickerGradient: linear-gradient(135deg, #26282d 0%, #181a1d 100%);
$fireawayAccent: #767eff;
$fairyPrimary: #140a2f;
$fairyGradient: linear-gradient(142.23deg, #5b5bbd -12.4%, #362761 151.71%);
$fernwayerGradient: linear-gradient(180deg, #d45d94 0%, #fa717d 100%);
$sevenPmPrimary: #121212;
$hotelDataCloudGradient: linear-gradient(116.68deg, #78ace4 0%, #5c92cc 100%);
$openSensePrimary: #f8faff;
$openSenseSecondary: #296efa;
$driveFocusSecondary: #ff820d;
$driveFocusPrimary: linear-gradient(180deg, $driveFocusSecondary 0%, #ffa34d 100%);
$separateUsAccent: #fb7478;
$famliciousBackground: #f3f5f8;
$famliciousAccent: #0b62ab;
$natpGradient: linear-gradient(180deg, #5e686d 0%, #292c2f 100%, #292c2f 100%);
$natpAccent: #81c242;
$travelTriviaGradient: linear-gradient(51.28deg, #e66bce 11.39%, #eb6b96 81.95%);
$travelTriviaAccent: #ee759e;
$cashChatAccent: #01ae5e;
$ubichatAccent: #2c54db;
$writerAccent: #2550f5;
$writerSecondary: #6d86f7;
$meateaterBackground: #221f20;
$meateaterGradient: linear-gradient(45deg, #221f20 0%, #3f3d3e 100%);
$bionoricaGradientTop: linear-gradient(180deg, #e5e9f1 0%, rgb(231 235 242 / 0%) 76.98%);
$bionoricaGradientMedium: linear-gradient(180deg, rgb(230 235 242 / 0%) 0%, #e6ebf2 52.6%, rgb(230 235 242 / 0%) 100%);
$bionoricaGradientBottom: linear-gradient(180deg, rgb(225 231 239 / 0%) 0%, #eff2f8 100%);
$bionoricaAccent: #014378;
$marketplaceAccent: #1a186d;
$marketplaceGradient: linear-gradient(90deg, #3953b0 0%, #6144d6 100%);
$fusionMarketsAccent: #19191f;
$fusionMarketsGradient: linear-gradient(90deg, #742cce 0%, #1811a2 100%);
$beautonomyBackground: #faf5f0;
$telemojoGradient: linear-gradient(180deg, #4398ff 0%, #0670f2 100%);
$telemojoSecondary: #f9fbfe;
$blackbirdPrimary: #111;
$blackbirdAccent: #0e75de;
$dinDonPrimary: #d4033e;
$dinDonSecondary: #fffafb;
$dinDonAccent: #a1c52b;
$fintechGradient: linear-gradient(113.07deg, #18b095 0%, #32c1a8 100%);
$fintechSecondary: #f8fdfc;
$smartCenterGradient: linear-gradient(180deg, #8192a1 0%, #5c6c7a 100%);
$humankindGradient: linear-gradient(112.74deg, #2f5997 0%, #16396d 100%);
$cinnabarGradientPrimary: linear-gradient(137.65deg, #f8776c -14.62%, #f0445f 105.11%);
$cinnabarGradientSecondary: linear-gradient(180deg, #fffafa 0%, #fffafa 87.68%, rgb(255 250 250 / 0%) 100%);
$realEstatePrimary: #010029;
$realEstateAccent: #2f5bf9;
$mobileBankPrimary: #1c1c1e;
$mobileBankAccent: #ffe603;
$crowdfundingSecondary: #292929;
$balzanoGradient: linear-gradient(120.48deg, #3184d6 0%, #0055a9 100%);
$mobileBudgetingAccent: #d2f069;
$mobileBudgetingPurple: #7988d9;
$mobileBudgetingRose: #f7afad;
$mobileBudgetingBlue: #46a1f1;
$forexAccent: #9079d8;
$forexSecondary: #f5f2fa;
$crowdfundingPlatformPrimary: #1c1c1c;
$crowdfundingPlatformSecondary: #292929;
$crowdfundingPlatformAccent: #1aba9d;
$carbonSpaceGradient: linear-gradient(254.67deg, #e3f0ff -0.23%, #fcfdff 100%) ;
$carbonSpaceAccent: #09f;
$metapixPrimary: #0d0e0f;
$p2pSecondary: #e5e5ff;
$p2pAccent: #5856d6;
$chatSolutionsWhite: #edefff;
$chatSolutionsBlue: #3953b0;
$kitchenSecondary: #f0f0ef;
$radioPlatoAccent: #d8fc52;
$radioPlatoAdditional: #9eb740;
$clairvoyantServicesPrimary: #0f0721;
$clairvoyantServicesAccent: #836dd9;
$digitalWalletPrimary: #121212;
$digitalWalletSecondary: #f5f5f5;
$digitalWalletBlue: #edfafd;
$digitalWalletPurple: #f2ecfa;
$digitalWalletGreen: #ecf8ef;
$digitalWalletOrange: #faf2df;
$erpAccent: #1890ff;
$erpSecondary: #f8fcff;
$hawkinPrimary: #25596e;
$hawkinAdditional: #62aab7;
$hawkinGreen: #e9fdee;
$hawkinBlue: #e3f3f5;
$hyveBlue: #46b0c3;
$hyveGreen: #56c691;
$hyvePrimary: #1a1a1d;
$hyveGradient: linear-gradient(180deg, #34343d 0%, #19191c 100%);
$gatewayAccent: #8c5ac2;
$gatewayBlue: #5664dc;
$gatewayGold: #c2a55a;
$gatewayGreen: #5ac2af;
$gatewayPink: #c25a9f;
$gatewayPrimary: #202020;
$gatewayPurple: #6130b1;
$generativeAiAccent: #6290e8;
$generativeAiGradient: linear-gradient(107.15deg, #6290e8 0%, #9e93ef 100%);
$generativeAiPurple: #9d62e8;
$generativeAiPrimary: #272e52;
$tasteMatchEgg: #fc0;
$tasteMatchMint: #70c598;
$tasteMatchCayenne: #ef4923;
$tasteMatchPepper: #d9d2c7;
$tasteMatchPlum: #9b4d4d;
$tasteMatchMashroom: #776f66;
$tasteMatchChocolate: #463f3a;
$travelBlue: #258ff1;
$travelBlack: #11131a;
$travelGray: #f8f9fb;

// new colors
$black-alpha: rgb(31 31 31 / 64%);
$white-alpha: rgb(255 255 255 / 64%);
