@import './variables';
@import './functions';

@mixin darkSliderButton {
  color: $gray0;

  * {
    fill: $gray0;
  }

  &:hover {
    * {
      color: $yellow500;
      fill: $yellow500;
    }
  }

  &[disabled] {
    opacity: 0.5;
    color: $gray400;

    * {
      fill: $gray300;
    }

    &:hover {
      cursor: default;

      * {
        fill: $gray300;
        color: $gray400;
      }
    }
  }
}

@mixin flex($fd: row, $jc: initial, $ai: initial, $fw: nowrap, $ac: initial) {
  display: flex;
  flex-flow: $fd $fw;
  justify-content: $jc;
  align-items: $ai;
  align-content: $ac;
}

@mixin hidden-text($textOverflow: ellipsis, $lineClamp: 1) {
  display: contents;
  overflow: hidden;
  text-overflow: $textOverflow;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
}

@mixin fontSize($size, $lineHeight: null) {
  font-size: calc-rem($size);

  @if $lineHeight {
    line-height: $lineHeight;
  }
}

@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin covered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin ultra-hd {
  @media (max-width: $ultra-hd) {
    @content;
  }
}

@mixin tablet-size-horizontal {
  @media (max-width: $tablet-size-horizontal) {
    @content;
  }
}

@mixin quad-hd {
  @media (max-width: $quad-hd) {
    @content;
  }
}

@mixin full-hd {
  @media (max-width: $full-hd) {
    @content;
  }
}

@mixin macbook-fifteen {
  @media (max-width: $macbook-fifteen) {
    @content;
  }
}

@mixin macbook-thirteen {
  @media (max-width: $macbook-thirteen) {
    @content;
  }
}

@mixin macbook-twelve {
  @media (max-width: $macbook-twelve) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $max-tablet-size) {
    @content;
  }
}

@mixin svga {
  @media (max-width: 815px) {
    @content;
  }
}

@mixin below-tablet {
  @media (max-width: $default-tablet-size) {
    @content;
  }
}

@mixin higher-phone {
  @media (max-width: 760px) {
    @content;
  }
}

@mixin below-tablet-horizontal {
  @media (max-width: $max-tablet-size) and (max-height: 800px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: $max-mobile-size) {
    @content;
  }
}

@mixin horizontal-mobile {
  @media (max-height: $horizontal-mobile) {
    @content;
  }
}

@mixin horizontal-big-mobile {
  @media (max-width: 830px) and (min-height: $horizontal-mobile) {
    @content;
  }
}

@mixin small-phone {
  @media (max-width: $max-mobile-size) and (max-height: 330px) {
    @content;
  }
}

@mixin horizontal-iphone-x {
  @media (max-width: 812px) and (min-height: 380px) {
    @content;
  }
}

@mixin phone-height-500 {
  @media (max-width: $max-mobile-size) and (min-height: 500px) {
    @content;
  }
}

@mixin phone-height-600 {
  @media (max-width: $max-mobile-size) and (min-height: 600px) {
    @content;
  }
}

@mixin phone-height-650 {
  @media (max-width: $max-mobile-size) and (min-height: 650px) {
    @content;
  }
}

@mixin phone-height-730 {
  @media (max-width: $max-mobile-size) and (min-height: 730px) {
    @content;
  }
}

@mixin phone-height-800 {
  @media (max-width: $max-mobile-size) and (min-height: 800px) {
    @content;
  }
}

@mixin phone-height-900 {
  @media (max-width: $max-mobile-size) and (min-height: 900px) {
    @content;
  }
}

@mixin iOSDevice {
  @supports (-webkit-touch-callout: none) {
    @content;
  }
}

@mixin signature-button {
  &Button {
    @include fontSize(16px);

    width: max-content;
    height: 46px;
    margin: 0 auto;
    padding: 11px 32px;
    border: 0;
    border-radius: 8px;
    background-color: $turbo-100;
    color: $dark-grey;
    font-family: $case-studies-font;
    transition: background 0.1s linear;
    cursor: pointer;

    &Container {
      display: flex;
    }

    &:active {
      background-color: $turbo-200;
    }
  }
}

@mixin h1-title {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 1.37px;
  text-align: center;

  @include phone {
    font-size: 1.8rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: $woodsmoke;
  }
}

@mixin big-title {
  @include horizontal-mobile {
    font-size: 3.125rem;
  }

  margin-bottom: 60px;
  font-weight: 800;
  font-size: 4.125rem;
  letter-spacing: 2.25px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  text-align: left;

  @include tablet {
    margin-top: 0;
  }

  @include below-tablet {
    font-size: 3rem;
  }

  @include phone {
    font-size: 2.5rem;
    margin-bottom: 25px;
  }
}

@mixin defaultFirstHeader {
  @include fontSize(70px);

  @include phone {
    @include fontSize(45px);
  }
}

@mixin defaultSecondHeader {
  @include fontSize(56px);

  @include tablet {
    @include fontSize(50px);
  }

  @include phone {
    @include fontSize(40px);
  }
}

@mixin button-more(
  $textColor: $white,
  $textHoverColor: $white,
  $backgroundColor: transparent,
  $backHoverColor: $white,
  $borderColor: $black,
  $borderHoverColor: $black,
) {
  position: relative;
  z-index: 2;
  width: 240px;
  margin-top: $more-button-indent;
  padding: 22px 0;
  line-height: 1.55;
  letter-spacing: 0.5px;
  border-radius: 10rem;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  color: $textColor;
  background-color: $backgroundColor;
  border: solid 1px $borderColor;
  will-change: transition;
  transition:
    background-color $shadow-time-for-yellow-button,
    border-color $shadow-time-for-yellow-button * 2,
    color $shadow-time-for-yellow-button,
    transform $shadow-time-for-yellow-button cubic-bezier(0, 0, 0.2, 1);

  &:hover {
    will-change: transition;
    background-color: $backHoverColor;
    border: solid 1px $borderHoverColor;
    color: $textHoverColor;
    transition:
      background-color $shadow-time-for-yellow-button,
      border-color $shadow-time-for-border-yellow-button,
      color $shadow-time-for-yellow-button,
      transform $shadow-time-for-yellow-button cubic-bezier(0, 0, 0.2, 1);
  }
}

@mixin buttonTransition {
  transition:
    background-color $shadow-time-for-yellow-button,
    border-color $shadow-time-for-yellow-button * 2,
    color $shadow-time-for-yellow-button,
    transform $shadow-time-for-yellow-button cubic-bezier(0, 0, 0.2, 1);
}

@mixin seLocationSubscribe($subscribesPosition, $articleCount) {
  @for $i from 1 to $articleCount {
    & > article:nth-of-type(#{$i}) {
      @if $i < $subscribesPosition {
        order: calc(#{$i} - 1);
      }

      @if $i == $subscribesPosition {
        order: calc(#{$subscribesPosition} - 1);
      }

      @if $i > $subscribesPosition {
        order: $i;
      }
    }
  }

  & > section:first-child {
    order: $subscribesPosition;
  }
}

@mixin caseStudyContainer {
  padding: 140px 40px;
  background-color: $white;

  .image {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 812px;
    margin: 0 auto;
    object-fit: contain;
  }

  [data-case-study-title] {
    color: $dark-grey;
  }

  [data-case-study-description] {
    color: $silver-chalice;
  }

  @include tablet {
    padding: 100px 40px;
  }

  @include phone {
    padding: 60px 15px;
  }
}

@mixin infoContainerPhone {
  @include phone {
    order: initial;
    margin: 0;
  }
}

@mixin wireframes {
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;

  & > div:first-of-type {
    padding: 0 40px;
  }

  @include phone {
    padding-left: 0;
    padding-right: 0;

    & > div:first-of-type {
      padding: 0 15px;
    }
  }
}

@mixin centeredBackgroundImage {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin iconsWithinCircle($col: $turbo-100, $defaultSize: 90px, $smallerSize: 60px) {
  .imageWrapper {
    @include flex(row, center, center);

    flex: 0 0 auto;
    width: $defaultSize;
    height: $defaultSize;
    border-radius: 50%;
    background: rgba($col, 0.7);
    margin-right: 16px;

    @include below-tablet {
      width: $smallerSize;
      height: $smallerSize;

      svg {
        transform: scale(0.7);
      }
    }
  }
}

@function color($c, $alfa: 1) {
  @return rgb(var($c), $alfa);
}

@mixin circle($color, $size) {
  content: '';
  position: absolute;
  border-radius: 50%;
  background-color: $color;
  width: $size;
  height: $size;
  z-index: -1;
  user-select: none;
  pointer-events: none;
}

@mixin listWithYellowMarkers() {
  @media (max-width: 924px) {
    height: 110px;
  }

  @include flex(column, initial, initial, wrap, space-around);

  padding: 0;
  max-width: 980px;
  width: 100%;
  margin: 0;

  & li {
    @include fontSize(16px, 150%);

    list-style: none;
    display: inline-block;
    max-width: 314px;

    & p {
      display: flex;
      margin: 0;
      position: relative;
      padding-left: 16px;
      overflow-wrap: break-word;

      &::after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        background-color: $yellow500;
        top: calc(50% - 4px);
        left: 0;
        border-radius: 100%;
      }
    }

    &:last-child {
      margin-bottom: 0;
      margin-right: 0;
    }
  }

  @include below-tablet {
    @include flex(column);

    height: fit-content;
  }
}

@mixin p2pSectionCard {
  margin: 32px 40px;
  border-radius: 40px;
  padding: 120px 0;

  @include tablet {
    border-radius: 30px;
    margin: 12px 16px;
    padding: 60px 16px;
  }
}
