@import './utils/variables';

html {
  margin: 0;
  padding: 0;
  font-family: $default-font;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  ::selection {
    background-color: $turbo-100;
  }

  body {
    margin: 0;
    padding: 0;

    & > div > section {
      background-color: $black;
    }

    [data-rmiz-overlay] {
      [data-rmiz-modal-content] {
        & > * {
          width: inherit;
          height: inherit;
        }
      }
    }

    [data-rmiz-btn-open] {
      outline: none;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
