@import './colors';

/* colors -- OUTDATED, check _colors.scss */

$turbo-100: #ffe603;
$turbo-200: #f5dc00;
$turbo-300: #e1c14c;
$turbo-400: #f9e104;
$turbo-500: #f8e001;
$turbo-600: #f5dd00;
$cerulean: #05a7ea;
$ferra: #714a4a;
$gold: #ffd603;
$black: #000;
$white: #fff;
$scarpa-flow: #4e4d51;
$silver: #b8b8b8;
$tuna: #373738;
$scorpion: #5c5c5c;
$science-blue: #026ed0;
$english-walnut: #3d2424;
$thunder: #30292a;
$star-dust: #9a9996;
$emperor: #555;
$iron: #d1d3d3;
$athens-gray: #efeff0;
$woodsmoke: #0e0e0f;
$mountain-mis: #9a999c;
$red: #f00;
$gray-nurse: #f7f8f7;
$tundora: #424242;
$athens-gray: #f8f8fa;
$flamingo: #f16522;
$aluminium: #a7a8ab;
$mine-shaft: #3a3a3a;
$nobel: #b7b7b7;
$gray: #7f7f7f;
$gray100: #eee;
$gray-200: #8c8c8c;
$gray-300: #ededed;
$gray400: #afafaf;
$gray500: #757575;
$mercury: #e6e6e6;
$mine-shaft: #333;
$dandelion: #ffd65d;
$mortar: #49414e;
$mid-gray: #6e6e6f;
$boulder: #767676;
$gray-suit: #d0cdd6;
$boulder: #7b7777;
$black-haze: #e8e9e9;
$flush-mahogany: #c43436;
$alizarin-crimson: #e21e1d;
$web-orange: #ffab00;
$scorpion: #695f62;
$gallery-100: #efefef;
$gallery-200: #ebebeb;
$alto: #d8d8d8;
$jumbo: #78777a;
$athens-gray: #f1f1f2;
$java: #22c1c3;
$cod-gray: #141414;
$mercury: #e2e2e2;
$timber-green: #13242a;
$mirage: #1a2a30;
$tundora: #454545;
$violet: #140a2f;
$cod-gray-1: #161616;
$silver-1: #cbcbcb;
$witch-haze: #fff492;
$silver-chalice: #a0a0a0;
$alabaster: #fafafa;
$dark-grey: #171717;
$pink: #d45d94;
$pink-lighter: #f96a77;
$light-pink: #fa717d;
$white-darker: #f8f8f8;
$dark-grey-1: #1a1c1f;
$dark-grey-2: #181a1d;
$grey-darker: #26282d;
$wild-sand: #f4f4f4;
$wild-send-2: #fafdfa;
$pale-sky: #6e7882;
$dark-blue: #1c1e20;
$dark-blue-1: #191b1e;
$pink-1: #ff2a89;
$light-red: #ff4934;
$logo-stroke: #25272c;
$light-violet: #5b5bbd;
$light-violet-1: #5c5abd;
$dark-violet: #362761;
$dark-violet-1: #252159;
$dark-violet-2: #16102b;
$violet: #5948ce;
$dodger-blue: #296efa;
$zircon: #f8faff;
$mercury-1: #e8e8e8;
$brink-pink: #fb7478;
$fuzzy-wuzzy-brown: #c45657;
$mine-shaft-1: #222020;
$sushi: #80c241;
$sushi-2: #a1c52b;
$shark: #2b2e30;
$shark-1: #292c2f;
$shark-2: #272d32;
$nevada: #666e72;
$nevada-1: #5e686d;
$nevada-2: #b9b9b9;
$mako: #40464a;
$prelude: #cdc7e7;
$grey-chateau: #a5a6a7;
$athens-gray-1: #f3f5f8;
$shuttle-gray: #586571;
$shuttle-gray-2: #626670;
$cornflower-blue: #767eff;
$flamenco: #ff820d;
$yellow-orange: #ffa34d;
$yellow: #fff381;
$dark-pink: #ee759e;
$light-grey: #fbfbfb;
$dark-pink-1: #e66bce;
$dolly: #fff281;
$regal-blue: #003b6a;
$regal-blue-2: #014378;
$green: #01ae5e;
$green-100: #72be47;
$midnight: #00152a;
$sea-blue: #2550f5;
$bright-blue: #bbf7e2;
$coffee: #faf5f0;
$slate-gray: #788898;
$slate-gray2: #768696;
$required-field-color: #ed655a;
$woodsmoke-2: #17191c;
$regent-gray: #8293a3;
$mariner: #2c54db;
$black-olive: #3f3d3e;
$raisin-black: #221f20;
$platinum: #e5e5e5;
$white-cloud: #f9fcff;
$slate-gray3: #778797;
$sushi-1: #81c242;
$shark-3: #2c2f31;
$nevada-2: #5e676d;
$famlicious-check-mark: #0b62ab;
$blue-ribbon: #046ff1;
$selago: #f9fbfe;
$bright-gray: #eee;
$sky-blue-white: #f1f7ff;
$catskill-white: #e6ebf2;
$catskill-white-2: #eff2f8;
$eerie-black: #19191f;
$ocean-blue: #5726bf;
$jacksons-purple: #2d1ba6;
$light-grayish-blue: #ebeef4;
$very-page-blue: #f5f7ff;
$light-grayish-blue-2: #d8ecfd;
$very-pale-lime-green: #f9fffa;
$chinese-black: #111;
$clear-chill: #2591ff;
$bright-navy: #0e75de;
$green-aloha: #18b095;
$melting-grey: #f5fbfb;
$cherry-red: #e40040;
$pale-pink: #fffafb;
$violet-blue: #3953b0;
$majorelle-blue: #6144d6;
$pure-blue-1: #007aff;
$pure-blue-2: #2f5997;
$pure-blue-3: #16396d;
$pure-blue-4: #2167ad;
$very-dark-gray: #161616;
$very-dark-blue: #010029;
$bright-ocean-blue: #2f5bf9;
$mint-blue-1: #5c92cc;
$mint-blue-2: #78ace4;
$mint-blue-3: #2570ba;
$mint-blue-4: #0e4780;
$froly: #f8776c;
$carnation: #f0445f;
$rose-white: #fffafa;
$eerie-black-2: #1c1c1e;

//transparency color
$pagination: rgb(35 37 44 / 25%);
$header-additional-color: rgb(255 255 255 / 100%);
$blog-search-background-color: rgb(255 255 255 / 98%);
$blog-selection-block-mobile-categories-border-color: rgb(0 0 0 / 50%);
$blog-subscribe-input-box-shadow-color: rgb(0 0 0 / 4%);
$blog-subscribe-input-box-shadow-focus-color: rgb(0 0 0 / 10%);
$work-beautonomy-title-shadow: rgb(0 0 0 / 25%);
$nearby-articles-block-box-shadow-color: rgb(0 0 0 / 7%);
$article-preview-box-shadow: 0 10px 60px -10px rgb(0 0 0 / 15%);
$card-bow-shadow: 0 8px 35px rgba($pale-sky, 0.1);
$feedback-form-grey: rgba(#171717, 0.3);
$case-study-data-cloud-background: rgb(120 172 228);
$case-study-humankind-background: rgb(47 89 151);
$case-study-humankind-wireframes: rgb(255 255 255 / 0%);
$case-study-dindon-paragraph: rgb(255 255 255 / 70%);
$case-study-dindon-background: rgb(255 255 255 / 15%);
$case-study-dindon-box-shadow: rgb(170 181 193 / 5%);
$case-study-step-box-shadow: rgb(110 120 130 / 10%);
$project-white: rgba($white, 0.4);

//time
$default-transition-time: 0.5s;
$button-transition-time: 0.3s;
$burger-transition-time: 0.8s;
$shadow-time-for-yellow-button: 0.4s;
$shadow-time-for-border-yellow-button: 0.1s;
$accelerated-transition-time: calc(#{$default-transition-time} / 2);
$changing-portfolio-color: 0.3s;

//radius
$buttonRadius: 60px;
$button-border-radius: 8px;
$articleRadius: 7.5px;

//sizes
$section-indent: 100px;
$mobile-section-indent: 80px;
$side-indent: 20px;
$more-button-indent: calc((#{$section-indent} / 2) + 20px);
$mobile-more-button-indent: calc(#{$mobile-section-indent} / 2);
$aricleIndent: 10px;
$after-mobile-title-margin: 60px;
$logo-size: 136px;
$max-content-width: 1300px;
$max-content-width-full-hd: 1170px;
$max-content-width-macbook-fifteen: 1050px;
$max-content-width-macbook-twelve: 1000px;
$max-content-width-small-section: 1076px;
$shadow-for-yellow-button: 0 5px 20px -10px rgb(0 0 0 / 57%);
$burger-item-1: 18px;
$burger-item-2: 24px;
$burger-item-3: 30px;
$header-height: 88px;

//resolutions size
$ultra-hd: 3840px;
$quad-hd: 2560px;
$full-hd: 1920px;
$macbook-fifteen: 1445px;
$macbook-thirteen: 1440px;
$desktop: 1366px;
$macbook-twelve: 1280px;
$tablet-size-horizontal: 1180px;
$max-tablet-size: 1024px;
$default-tablet-size: 768px;
$max-mobile-size: 568px;
$horizontal-mobile: 450px;

//fonts
$default-font: 'Poppins', sans-serif;
$case-studies-font: 'Poppins', sans-serif;
$main-title-font: 'Poppins', sans-serif;
$signature-font: 'Arial', sans-serif;
$roboto-font: 'Roboto', sans-serif;

//insta
$imgIndent: 7.5px;
$box_h: 250px;
$border: 30px;
$initial-radius: 50px;
$max-radius: 300px;
$gradient-border: linear-gradient(45deg, #fccc63, #e95950, #bc2a8d);
$gradient-dot: linear-gradient(45deg, #e95950, #bc2a8d);
$insta-tape-box-shadow-color: rgb(0 0 0 / 7%);

//margins
$min-article-margin: 14px;
$max-article-margin: 24px;

//filters
$shadow-filter: drop-shadow(-18.8201px 18.8201px 56.4602px rgb(0 0 0 / 60%));
$shadow-filter-image: drop-shadow(2px 2px 3px #000);
$shadow-filter-phone: drop-shadow(-12.8201px 12.8201px 30.4602px rgb(0 0 0 / 40%));

//timing functions
$primary-bezier: cubic-bezier(0.165, 0.84, 0.44, 1);

// TODO rework it with enviroment variable
$cdnURL: 'https://dn75rr69j9vc.cloudfront.net';

//cards common
$card-border: solid 1px $mercury;

@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
